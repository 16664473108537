<template>
  <div>
    <!-- pic2022220A2DF196F96FD333B0E0D944B2.jpg -->
    <img class="rounded img-large" src="">
    <p>
      A few of you lucky fans will soon have your creations featured here on the LEGO Universe News Network!
    </p>
    <p>
      The LEGO Universe art director and a lead FX artist will select six submissions from the “Pirates: Create a Watercraft” and the “Ninjas: Make a hideout” creative challenges. Our art pros are then going to give some great feedback on their picks!
    </p>
    <p>
      LEGO Universe newsletter subscribers will be first to find out which submissions get selected! If you haven’t subscribed to the newsletter yet, be sure to
      <router-link :to="{ name: 'newsletter', params: {} }">sign up</router-link>
      before <b>Sunday, March 22</b> to get the exclusive sneak peek!
    </p>
  </div>
</template>
